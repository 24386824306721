import * as React from "react";
import { styled } from "@mui/material/styles";
import DocumentTitle from "react-document-title";
import { useLocation } from "react-router";
import { Navigate, Route, Routes } from "react-router-dom";
import { NavUtils, Page } from "../utils/navUtils";
import { ScrollToTop } from "./common/scrollToTop";
import { PageFrame } from "./general/pageFrame";
import { getGlobalServices } from "../services/services";
import { LoginWithToS } from "./general/loginWithToS";
import { IntraFinance } from "./intra/IntraFinance";

const APP_TYPE = "intra" as const;

export function IntraApp() {
    const location = useLocation();

    /** Log screen views when the path changes */
    React.useEffect(() => {
        getGlobalServices()?.firebaseAnalyticsService.logPageView();
    }, [location.pathname]);

    return (
        <DocumentTitle title={NavUtils.getNavUrlSimpleTitle[Page.Home]}>
            <ScrollToTop>
                <AppContainer>
                    <AppContent>
                        <Routes>
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.Home]}
                                element={<Navigate to={NavUtils.getNavUrl[Page.IntraFinance]()} />}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.SignIn]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.SignIn]}
                                        appType={APP_TYPE}
                                    >
                                        <LoginWithToS />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.IntraFinance]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.IntraFinance]}
                                        authentication={[]}
                                        appType={APP_TYPE}
                                    >
                                        <IntraFinance />
                                    </PageFrame>
                                )}
                            />

                            <Route element={<Navigate to={NavUtils.getNavUrl[Page.Home]()} />} />
                        </Routes>
                    </AppContent>
                </AppContainer>
            </ScrollToTop>
        </DocumentTitle>
    );
}

// Styles
const AppContainer = styled("div")`
    display: flex;
    flex-direction: column;
`;

const AppContent = styled("div")`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
`;
