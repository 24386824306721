import {
    Alert,
    AlertTitle,
    CircularProgress, Grid, Paper, Typography,
} from "@mui/material";
import { IFunctionsMembersGetMembershipInfoResponse } from "@resistance-tech/api";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { getGlobalServices } from "../../services/services";
import { assertNever } from "../../utils/general";

export function IntraFinance() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [membershipInfo, setMembershipInfo] = useState<IFunctionsMembersGetMembershipInfoResponse | undefined>(
        undefined,
    );
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const execute = async () => {
            setIsLoading(true);
            try {
                const response = await getGlobalServices()?.functionsService.membersGetMembershipInfo({});
                setMembershipInfo(response);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Nem sikerült a betöltés - kérjük frissítsd a lapot!", { variant: "error" });
            } finally {
                setIsLoading(false);
            }
        };
        execute();
    }, [enqueueSnackbar]);

    let errorComponent: React.ReactNode | null = null;
    if (membershipInfo?.type === "error") {
        switch (membershipInfo.errorType) {
        case "email-not-verified":
            errorComponent = (
                <>
                    <Typography variant="body1" paragraph>
                        Kérjük, erősítsd meg az e-mail-címedet! Az e-mail-címedre kellett,
                        hogy érkezzen egy megerősítő levél.
                    </Typography>
                </>
            );
            break;
        case "error-member-not-found":
            errorComponent = (
                <>
                    <Typography variant="body1" paragraph>
                        Nem találtunk tagot ezzel az e-mail-címmel.
                    </Typography>
                    <Typography variant="body1">
                        Kérjük vagy jelentkezz be azzal az e-mail-címeddel, amelyiket a tagi rendszerben is használsz,
                        vagy változtasd meg az e-mail-címedet a
                        {" "}
                        <a href="https://tag.momentum.hu/" target="_blank" rel="noreferrer">tagi rendszerben</a>
                        . Ha az utóbbit teszed, akkor várnod kell másnapig, hogy az e-mail-címed frissüljön ebben
                        a rendszerben.
                    </Typography>
                </>
            );
            break;
        case "error-multiple-members-found":
            errorComponent = (
                <>
                    <Typography variant="body1" paragraph>
                        Több tagot is találtunk ezzel az e-mail-címmel.
                    </Typography>
                    <Typography variant="body1">
                        Kérjük jelezd a
                        {" "}
                        <a href="mailto:hr@momentum.hu" target="_blank" rel="noreferrer">hr@momentum.hu</a>
                        {" "}
                        címen, hogy az e-mail-címed több profilhoz is hozzá van rendelve!
                        Ők tudnak segíteni abban, hogy a nem megfelelő tag(ok)tól eltávolítsák az e-mail-címedet.
                    </Typography>
                </>
            );
            break;
        default:
            assertNever(membershipInfo.errorType, `Unexpected error type: ${membershipInfo.errorType}`);
        }
    }

    return (
        <Paper elevation={0} sx={(theme) => ({ margin: theme.spacing(2), padding: theme.spacing(2) })}>
            {isLoading && (
                <Grid container justifyContent="center" sx={{ width: "100%" }}>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            {errorComponent}
            {membershipInfo?.type === "success" && (
                <>
                    <Typography variant="h4" paragraph>
                        Tagdíjkiegészítés
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Akik tisztséggel rendelkeznek, azoknak tagdíjkiegészítést kell fizetniük.
                    </Typography>
                    {membershipInfo.tdkSummary && (
                        <Alert severity="info" sx={(theme) => ({ marginBottom: theme.spacing(4) })}>
                            <AlertTitle>
                                A jelenlegi egyenleged:
                                {" "}
                                <strong>
                                    {membershipInfo.tdkSummary.balance}
                                    {" "}
                                    Ft.
                                </strong>
                            </AlertTitle>
                            <Typography variant="body1" paragraph>
                                Legutóbbi frissítés időpontja:
                                {" "}
                                {membershipInfo.tdkSummary.lastUpdateDate}
                                .
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Ha ez a szám negatív, akkor tartozol. Ha pozitív, akkor jelenleg túlfizetésed van.
                            </Typography>
                            <Typography variant="body1">
                                Ha te más összegről tudsz vagy bármi más kérdésed van, írj a
                                {" "}
                                <a href="mailto:penzugy@momentum.hu" target="_blank" rel="noreferrer">penzugy@momentum.hu</a>
                                {" "}
                                címre!
                            </Typography>
                        </Alert>
                    )}
                    {membershipInfo.tdkEntries.length === 0 ? (
                        <Typography variant="body1" paragraph>
                            Nem szerepel tisztséged a nyilvántartásunkban.
                        </Typography>
                    ) : (
                        <Typography variant="body1" paragraph>
                            Az alábbi tisztségeid szerepelnek a nyilvántartásunkban.
                        </Typography>
                    )}
                    <Typography variant="body1" paragraph>
                        Ha a nyilvántartott tisztségeken kívül van tisztséged, akkor az alapszabály értelmében
                        kötelező ezt jelentened a
                        {" "}
                        <a href="mailto:penzugy@momentum.hu" target="_blank" rel="noreferrer">penzugy@momentum.hu</a>
                        {" "}
                        címen, a megfelelő tagdíjkiegészítést pedig utólag is köteles vagy befizetni.
                    </Typography>
                    {membershipInfo.tdkEntries.length > 0 && (
                        <MaterialTable
                            title="Tisztségek"
                            columns={[
                                { title: "Munkáltató", field: "employer" },
                                { title: "Pozíció", field: "position" },
                                { title: "Kategória", field: "category" },
                                { title: "Fizetés (nettó)", field: "netSalary" },
                                { title: "Kezdés dátuma", field: "startDate", defaultSort: "desc" },
                                { title: "Befejezés dátuma", field: "endDate" },
                            ]}
                            data={membershipInfo.tdkEntries}
                        />
                    )}
                </>
            )}
        </Paper>
    );
}
